<template>
  <div class="succPage">
    <div class="imgShow">
      <img src="../../assets/xsqd/suc.png" class="btnImg" />
      <div class="textShow">提交成功</div>
    </div>
    <div class="orderShow">
      <div v-if="orderNos">
        您的订单号为：
        <span>{{orderNos}}</span>
      </div>
      <div v-if="correctNo">
        您的批单号为：
        <span>{{correctNo}}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      correctNo:"",
      orderNos:"",
    };
  },
  created() {
    let url=window.location.href;
    this.correctNo = this.getUrlParameter(url, "correctNo");
    this.orderNos = this.getUrlParameter(url, "orderNos");
  },
  methods: {
    getUrlParameter(url, param) {
      var pageUrl = url.split("?");
      var urlVariables = pageUrl[1].split("&");
      for (var i = 0; i < urlVariables.length; i++) {
        var parameterName = urlVariables[i].split("=");
        if (parameterName[0] === param) {
          return parameterName[1];
        }
      }
      return null;
    }
  }
};
</script>
<style lang="stylus" scoped>
.succPage {
  overflow: hidden;

  .imgShow {
    margin: 2rem auto 0.8rem;
    text-align: center;

    .btnImg {
      height: 2rem;
      width: 2rem;
    }

    .textShow {
      font-size: 0.6rem;
      font-family: PingFang-SC-Bold;
      font-weight: bold;
      color: #3E3E3E;
    }
  }

  .orderShow {
    margin: auto;
    text-align: center;
    line-height: 0.8rem;

    span {
      color: #4A89FD;
      font-size: 0.5rem;
    }
  }
}
</style>

